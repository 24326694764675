/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { propertyJSONMeta as t } from "./property.js";
function r(r, o, e) {
  let i, n;
  return void 0 === o ? (n = r, i = [void 0]) : "string" != typeof o ? (n = r, i = [void 0], e = o) : (n = o, i = Array.isArray(r) ? r : [r]), (r, o) => {
    const p = r.constructor.prototype;
    for (const c of i) {
      const i = t(r, c, n);
      i.write && "object" == typeof i.write || (i.write = {}), e && (i.write.target = e), i.write.writer = p[o];
    }
  };
}
export { r as writer };